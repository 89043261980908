<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/guanyu/ry_banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <div>
                <el-image style="width:100%" :src="require('@/assets/guanyu/rongyu'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.png')"></el-image>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            title:''
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.keywords')
            },
            {
                name: 'description',
                content: this.$t('seo.description')
            }
        ]
        }
    },
    created(){
        this.title = this.$t('layout.honor')+'-'+this.$t('seo.title')
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 70%;
        margin-top: 1%;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 100%;
        }
    }
</style>